<template>
    <div>
      <transferenceManagebar/>
      <v-container fluid>
        <base-material-card
          icon="mdi-clipboard-text"
          title="กำหนดการปฏิทินการย้าย สายบริหาร"
          class="px-5 py-3"
        >
          <v-card class="mb-4 pa-2">
            <v-row>
              <v-col cols="12" md="12" class="text-right">
                <v-timeline :dense="$vuetify.breakpoint.smAndDown">
                  <v-timeline-item color="grey lighten-1" fill-dot left small>
                    <v-card>
                      <v-card-title class="grey lighten-1 justify-end">
                        <h2 class="mr-4 white--text font-weight-light">
                          กำหนดการ <span class="red--text">ผู้อำนวยการวิทยาลัย</span> 
                        </h2>
                        <v-icon dark size="42">
                          mdi-calendar
                        </v-icon>
                      </v-card-title>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12">
                                <PeriodDirectors/>
                          </v-col>                       
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-timeline-item>                                  
  
                  <v-timeline-item color="grey lighten-1" fill-dot left small>
                    <v-card>
                      <v-card-title class="grey lighten-1 justify-end">
                        <h2 class="mr-4 white--text font-weight-light">
                          กำหนดการ <span class="red--text">รองผู้อำนวยการวิทยาลัย</span> 
                        </h2>
                        <v-icon dark size="42">
                          mdi-calendar
                        </v-icon>
                      </v-card-title>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12">
                             <periodSeDirectors/>
                          </v-col>                       
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-timeline-item>                                  
                 
                  <v-timeline-item color="info lighten-1" fill-dot right>
                    <v-card>
                      <v-card-title class="info lighten-1">
                        <v-icon dark size="42" class="mr-4">
                          mdi-calendar
                        </v-icon>
                        <h2 class="white--text font-weight-light">
                         สอจ. (พิจารณาย้ายผู้บริหาร) 
                        </h2>
                      </v-card-title>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12">
                               <PeriodVecprovincesManage/>
                          </v-col>                     
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-timeline-item>
  
  
  
                  <v-timeline-item color="purple lighten-2" fill-dot left>
                    <v-card>
                      <v-card-title class="purple lighten-2">
                        <v-icon dark size="42" class="mr-4">
                          mdi-calendar
                        </v-icon>
                        <h2 class="white--text font-weight-light">
                         วิทยาลัยปลายทาง (รับฟังความคิดเห็น) 
                        </h2>
                      </v-card-title>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12">
                                <periodCollegediscussDirector/>
                          </v-col>                     
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-timeline-item>
  
  
              
                </v-timeline>
              </v-col>
            </v-row>
          </v-card>
        </base-material-card>
      </v-container>
    </div>
  </template>
  
  <script>


import PeriodDirectors from '../../../components/admin/periodDirectors.vue';
import periodSeDirectors from '../../../components/admin/periodSeDirectors.vue';
import periodCollegediscussDirector from '../../../components/admin/periodCollegediscussDirector.vue';
import PeriodVecprovincesManage from '../../../components/admin/periodVecprovincesManage.vue';
import transferenceManagebar from '../../../components/admin/transferenceManagebar.vue';



  
  
  export default {
      name: "HRvecTransferTeachers",
      data() {
          return {
              loading: true,
              ApiKey: "HRvec2021",
          };
      },
      mounted() { },
      methods: {},
      components: { PeriodDirectors, periodSeDirectors, periodCollegediscussDirector, PeriodVecprovincesManage,transferenceManagebar }
    }
  </script>
  
  <style lang="scss" scoped></style>
  